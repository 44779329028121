
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
	name: 'FooterSocialMedia'
})
export default class FooterSocialMedia extends Vue {
	@Prop({
		type: String,
		required: true,
		validator: (value: string) => ['user', 'company'].includes(value)
	})
	readonly styling!: string;
}
